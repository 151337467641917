import Head from 'next/head';

export interface HeadProps {
	title: string;
	description: string;
	lang?: string;
	keywords?: string[];
	author?: string;
	image?: string;
	meta?: Array<{
		name: string;
		content: string;
	}>;
}

const SeoHead = ({
	title = 'Deepankar Sharma - Full Stack Engineer',
	description,
	author = 'Deepankar Sharma',
	keywords,
	image = './images/deepankar.online.png',
	meta,
}: HeadProps) => {
	return (
		<Head>
			<title>{title}</title>
			<meta name="description" content={description} />
			<meta name="author" content={author} />
			<meta name="og:title" content={title} />
			<meta name="og:description" content={description} />
			<meta name="og:type" content="website" />
			<meta name="og:url" content="https://deepankar.online/" />
			<meta name="twitter:card" content="summary" />
			<meta name="twitter:title" content={title} />
			<meta name="twitter:description" content={description} />
			<meta name="twitter:creator" content={author} />
			{meta &&
				meta.length > 0 &&
				meta.map(({ name, content }) => (
					<meta name={name} content={content} key={name} />
				))}
			{image && <meta name="og:image" content={image} />}
			{image && <meta name="twitter:image" content={image} />}
			{keywords && <meta name="keywords" content={keywords.join(', ')} />}
			<meta charSet="utf-8" />
			<meta name="viewport" content="initial-scale=1.0, width=device-width" />
			<script
				type="application/ld+json"
				dangerouslySetInnerHTML={{
					__html: `{"@context":"http://schema.org","@type":"WebSite","name":"deepankar.online","alternateName":"Deepankar Sharma","url":"https://deepankar.online/","description":"Full-Stack Software Developer with a focus on Python, Django, Typescript and React.js. I have more than 5 years of experience working in software engineering.","image":"${image}"}`,
				}}
			/>
			<script
				type="application/ld+json"
				dangerouslySetInnerHTML={{
					__html: `{"@context":"http://schema.org","@type":"Person","image":"https://miro.medium.com/fit/c/176/176/1*kEM9G3_eqt16qUS-tiHSHA.jpeg","name":"Deepankar Sharma","alternateName":"Deepankar","url":"https://deepankar.online/","jobTitle":["Senior Software Engineer","Full-Stack Engineer","Back-End Engineer","Web Developer","Software Consultant"],"hasOccupation":{"@type":"Occupation","name":"Senior Software Engineer","estimatedSalary":[{"@type":"MonetaryAmountDistribution","name":"base","currency":"USD","duration":"P1H","percentile10":"74","percentile25":"80","median":"90","percentile75":"100","percentile90":"106"}],"description":"Develops web applications and websites using Python, Typescript, React and Django.","skills":"HTML5, CSS, JavaScript, React, PHP, Sass, Less, Node.js, Express.js, Vue.js, Gatsby, Next.js, JavaScript Frameworks, Git, Github, NPM, SEO, CMS, WordPress, Joomla, Drupal, E-Commerce","alternateName":["Backend Developer","Full Stack Developer","Senior Software Engineer"],"responsibilities":["App Development","Backend Development","Full Stack Development","Python Development","Database Design","DevOps","Hasura","TypeScript Development","Web Developer"]},"sameAs":["https://linkedin.com/in/deepankar-sharma","https://twitter.com/geeky_lad","https://github.com/cquark7"],"brand":[{"@type":"Brand","name":"Software Engineer","alternateName":"Full-Stack Engineer"},{"@type":"Brand","name":"Deepankar","alternateName":"Deepankar Sharma"}],"homeLocation":{"@type":"City","name":"Kota"},"alumniOf":{"@type":"Organization","url":"https://www.skit.ac.in/","name":"Swami Keshvanand Institute of Technology, Management & Gramothan (SKIT)"},"nationality":{"@type":"Country","name":"India"}}`
				}}
			/>
		</Head>
	);
};

export { SeoHead };
